import React from "react"

class Footer extends React.Component {
    render() {
        return (
            <div id={'footer'}>
                <p>Copyright 2022 RewardPool</p>
            </div>
        )
    }
}

export default Footer